import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import Widget from "../../components/layout/widget"
import { useComments } from "../../hooks/use-comments"
import { CommentTarget, Comment } from "../../types"
import CommentInput from "./input"
import CommentItem from "./comment-item"
import DonationInput from "./donation-input"
import Notice from "../widgets/notice"
import { useScroll } from "../../hooks/use-scroll"
import { GET_donations } from "../../services/http"
import { useFilters } from "../../hooks/use-filters"
import { convertComments } from "../../services/content"
import { useIndexedDB } from "../../hooks/use-indexeddb"
import { useLazyLoading } from "../../hooks/use-lazy-loading"

type Props = {
  target: CommentTarget
  parentComment: string
}

const Donations: FunctionComponent<Props> = ({ target, parentComment }) => {
  const {
    comments,
    setComments,
    upvoteComment,
    downvoteComment,
    publishComment,
    hideComment,
  } = useComments(target, [], true, parentComment)

  const { filters, commentFilters } = useFilters()
  const { muteComments } = useIndexedDB()

  const fetchPage = useCallback(
    async (offset: number) => {
      //console.log("fetchPage")
      const response = await Promise.resolve(
        // TODO: prevent requests if no data
        GET_donations(target.id, {
          count: 20,
          offset: offset * 20,
          sort: commentFilters.sort,
          threshold: commentFilters.threshold,
          unread: commentFilters.unread ? 1 : 0,
        })
      )
      if (response?.hits?.hits.length) {
        return convertComments(response, filters)
      } else return []
    },
    [commentFilters, filters]
  )

  const add = useCallback(
    (newItems: Comment[], offset: number) => {
      if (newItems) {
        //console.log(feedId + " " + offset + " " + newItems)
        if (offset == 0 && comments.length > 0) {
          console.log("clearing items")
          setComments(newItems)
        } else setComments(comments => comments.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [comments]
  )

  useEffect(() => {
    //console.log("reloading")
    resetLoader()
  }, [commentFilters])

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<Comment>(comments, { fetchPage, add })

  const [count, setCount] = useState(target.count)
  const onComment = useCallback(
    (amount: number, text: string, commentId: string) => {
      setCount(count => count + 1)
      publishComment(amount, text, commentId)
    },
    [count, publishComment]
  )

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Notice id="feedback_notice" submit="Good">
          <p>
            Here is the place to make a donation with a message or question
            attached. Please note that this donation will not influence content
            visibility in the charts view.
          </p>
        </Notice>
      </div>
      <Widget title="Donations" numComments={count}>
        <ul>
          <li>
            <DonationInput
              onSubmit={onComment}
              borderless
              autofocus={true}
              placeholder="Write your feedback or question..."
              maxChars={512}
            />
          </li>

          {comments.map(comment => (
            <li key={comment.id}>
              <CommentItem
                target={target}
                comment={comment}
                onLike={upvoteComment}
                onDislike={downvoteComment}
                publishComment={publishComment}
                hideComment={hideComment}
                isDonation={true}
              />
            </li>
          ))}
        </ul>
      </Widget>
      <style jsx>{`
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        li:not(:first-child) {
          margin-top: 1em;
        }
        li:not(:last-child) {
          margin-bottom: 1em;
        }
      `}</style>
    </>
  )
}

export default Donations
